<template>
  <div class="incomedetails">
    <!-- 表格start -->
    <tablelist
      :tableArray="tableArray"
      :defaultTable="defaultTable"
      ref="table"
      @currentMethod="currentMethod"
      :isPage="true"
      :total="total"
      :pageSize="params.page_size"
    ></tablelist>
    <!-- 表格end -->
  </div>
</template>
<script>
import api from "../../http/api";
export default {
  components: {
    tablelist: () => import("../components/table"),
  },
  data() {
    return {
      params: {
        page_index: 1,
        page_size: 40,
      },
      loading: false,
      tableH: 0,
      tableArray: [
      {
          key: "user_name",
          name: "注册账号",
           width:'50px',
        },
        {
          key: "group_type_desc",
          name: "群类型",
          width:'30px',
        },
        {
          key: "group_name",
          name: "群名",
        },
       
        {
          key: "need_points",
          name: "消费积分",
        },
       
        {
          key: "create_time",
          name: "消费时间",
        },
      {
          key: "commission_state",
          name: "结算状态",
        },
        {
          key: "profits",
          name: "实际利润",
        },
      ],
      total:0,
      defaultTable: [],
    };
  },
  // created() {
  //   this.getList();
  // },
  activated() {
    this.getList();
  },
  methods: {
    currentMethod(index) {
      this.getList(index);
    },
    //获取列表数据
    async getList(data) {
      let that = this;
      if (data) that.params.page_index = data;
      let result = await api.getWithdrawalDetail(that.params);
      if (result.code == 200) {
        let res = result.response;
        that.total = res.total;
        that.defaultTable = res && res.data.length != 0 ? res.data.map(item=>{
          return {
            ...item,
            user_name:that.countText(item.user_name),
            commission_state:item.commission_state==1?'待结算':(item.commission_state==2?'结算完成':'')
          }
        }) : [];
      }
    },
    //计算字符
    countText(data){
      return data.substring(0,Math.round(data.length/2))+'***'
    }
  },
};
</script>
<style lang="less" scoped>
  .incomedetails {
    width: 100%;
    height: 100%;
  }
  </style>
